import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserFunctionsService } from '../services-functions/user-functions.service';
import { AuthenticationFunctionsService } from '../services-functions/authentication-functions.service';

const redirectTo = JSON.parse(localStorage.getItem('login'));
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  imagen_fondo_login: any =
    'assets/images/Fondo-inicio-Royal-films.jpg';

  vector_login = {
    email: '',
    password: '',
  };

  constructor(
    private router: Router,
    private userFunctionsService: UserFunctionsService,
    private authenticationFunctionsService: AuthenticationFunctionsService
  ) {}

  ngOnInit(): void {
    // if (this.authenticationFunctionsService.currentUserValue) {
    //   if(JSON.parse(localStorage.getItem('login')).user_type != '3' ){
    //     this.router.navigate(['/pages-admin']);
    //   }else{
    //     this.router.navigate(['/pages-admin/punto-de-venta']);
    //   }
    // }
  }

  function_user_login = (email, password) => {
    this.userFunctionsService
      .function_user_login({ email, password })
      .then((res) => {
        localStorage.setItem('login', JSON.stringify(res));
        this.authenticationFunctionsService.currentUserSubject.next(res);
          this.router.navigate(['/pages-admin']);
       
      });
  };
}
