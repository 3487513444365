import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
// import { RecoverPasswordFormFunctionsService } from '../services-functions/reset_password_functions.service';
import { FunctionsService } from '../services-functions/functions.service';
import { RecoverPasswordFunctionsService } from '../services-functions/recover_password_functions.service';

// const redirectTo = JSON.parse(localStorage.getItem('recover-password-form-form-form'));
@Component({
  selector: 'app-recover-password-form',
  templateUrl: './recover-password-form.component.html',
  styleUrls: ['./recover-password-form.component.css'],
})
export class RecoverPasswordFormComponent implements OnInit {
  imagen_fondo_login: any =
    'assets/images/Fondo-inicio-Royal-films.jpg';

  vector_datos = {
    email: ''
  }

  constructor(
    private recoverPasswordService:RecoverPasswordFunctionsService,
    private functionsService: FunctionsService,
    private router: Router,
    private spinner: NgxSpinnerService

  ) {}

  ngOnInit(): void {
  
  }

  public function_reset_password(){
    this.recoverPasswordService.function_recover_password(this.vector_datos)
    .then(res => {
      this.router.navigate(['/login']);

    })
    }
}
