import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const url = environment.SERVICES_URL;

@Injectable({
  providedIn: 'root'
})
export class RecoverPasswordServicesService {

  constructor(private http: HttpClient) { }

  function_recover_password = (body) => {
    return this.http.post<any>(`${url}/sendResetPasswordEmail`,body)
  }

  function_reset_password = (body) => {
    return this.http.post<any>(`${url}/resetPasswordEmail`,body)
  }


}