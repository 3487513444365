import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { RecoverPasswordServicesService } from '../services/recover_password.services.service';
import { FunctionsService } from './functions.service';

@Injectable({
  providedIn: 'root',
})
export class RecoverPasswordFunctionsService {
  constructor(
    private recoverPasswordService: RecoverPasswordServicesService,
    private functionsService: FunctionsService,     private spinner: NgxSpinnerService
  ) {}

  function_recover_password = (body) => {
    this.spinner.show()
    return new Promise((resolve,reject) => {
      this.recoverPasswordService.function_recover_password(body)
      .subscribe(res => {
        res!=''?this.spinner.hide():this.spinner.show();
        res.data.length==0?
        this.functionsService.function_modal_img(
          'Hola amigo',
          '¡Este correo electrónico no se encuentra registrado!'
        ):  this.functionsService.function_modal_img(
          'Hola amigo',
          '¡Envío de correo electrónico exitoso!'
        ).then(respuesta=>{
          resolve('');
        })
      },err => {
        this.functionsService.function_modal_img(
          'Hola amigo',
          'Tuvimos un pequeño problema al consultar el servicio de recuperar contraseña, no eres tú somos nosotros, porfavor intentar mas tarde'
        );
      })
    })
  }

  function_reset_password = (body) => {
    return new Promise((resolve,reject) => {
      this.recoverPasswordService.function_reset_password(body)
      .subscribe(res => {
        res!=''?this.spinner.hide():this.spinner.show();
        res.data.length==0?
        this.functionsService.function_modal_img(
          'Hola amigo',
          '¡Este link ya no está habilitado, por favor realiza la operación de nuevo!'
        ):  resolve('')
      },err => {
        this.functionsService.function_modal_img(
          'Hola amigo',
          'Tuvimos un pequeño problema al consultar el servicio del restaurar contraseña, no eres tú somos nosotros, porfavor intentar mas tarde'
        );
      })
    })
  }





  
}
