import { Injectable } from '@angular/core';
import Swal from 'sweetalert2'
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {

  vector_info_ventas = new ReplaySubject<any>(1);

  constructor() { }

  function_modal_img = (title,text) => {
    return new Promise((resolve,reject) => {
      Swal.fire({
        title: title,
        text: text,
        imageUrl: '/assets/images/kiosko/cineMobilRoyalFilmsBlanco.png',
        confirmButtonColor: '#2d4f82',
        customClass: {
          image: 'img_sweet',
          popup: 'popup_class',
        }
      }).then(result => {
        resolve(true)
      })
    })
  }

  function_modal_img_option = (title,text) => {
    return new Promise((resolve,reject) => {
      Swal.fire({
        title: title,
        text: text,
        imageUrl: '/assets/images/kiosko/cineMobilRoyalFilmsBlanco.png',
        confirmButtonColor: '#2d4f82',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        customClass: {
          image: 'img_sweet',
          popup: 'popup_class',
        }
      }).then(result => {
        if (result.value) {
          resolve(true)
        }else{
          resolve(false)
        }
      })

    })
  }
}
