import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const url = environment.SERVICES_URL;

@Injectable({
  providedIn: 'root'
})
export class UserServicesService {

  constructor(private http: HttpClient) { }

  function_user_login = (body) => {
    return this.http.post<any>(`${url}/admin/user/login`,body)
  }

  
  function_consultar_user = () => {
    return this.http.get<any>(`${url}/admin/user/list`)
  }

  function_filter = (body) => {
    return this.http.post<any>(`${url}/admin/user/filter`, body)
  }

  function_create_user = (data) => {
    let info = new FormData();
    info.append('username',data.username);
    info.append('email',data.email);
    info.append('password',data.password);
    info.append('dateCreate',data.dateCreate);
    info.append('dateUpdate',data.dateUpdate);
    info.append('userType',data.userType);
    info.append('company',data.company);

    return this.http.post<any>(`${url}/admin/user/create`,info)
  }

  function_edit = (data) => {

    return this.http.post<any>(`${url}/admin/user/edit`,data)
  }

  
  function_update_state = (data) => {

    return this.http.post<any>(`${url}/admin/user/update_state`,data)
  }

  
  function_delete = (data) => {

    return this.http.post<any>(`${url}/admin/user/delete`,data)
  }


}