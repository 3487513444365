// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// http://44.228.64.178:4000

export const environment = {
  production: false,
  SERVICES_URL: 'https://back1-royalfilm.virtual-tec.com/api/v2', //'http://190.85.227.213:4080',
  apiUrl: 'https://back2-royalfilm.virtual-tec.com/api/v1', //'http://190.85.227.213:4040',

  id_theater: '62',
  EWAUTH: {
    userId: 'mtorres',
    terminalId: 'TMR16',
    sitId: '052',
    esComplejo: 0,
    db: 'Pruebas_Ewave_JardinplazaCuc',
    ip: '190.145.72.140',
    tiempoMaxMovieBuy: 'SLSEXTLIM',
    seatMAx: 'MAXSEATSEL'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
