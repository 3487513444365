<ngx-spinner [fullScreen] = "true" bdColor = "rgba(0,0,0,0.41)" size = "medium" color = "#fff" type = "ball-pulse" [fullScreen] = "false">
  <p style="color: white; font-size: 20px" > Enviando correo electrónico...</p>
</ngx-spinner>

<div
class="
  container-fluid
  h-100
  background_position_center background_repeat_no_repeat background_size_cover
"
[ngStyle]="{ 'background-image': 'url(' + imagen_fondo_login + ')' }"
>
<div class="row justify-content-center" style="height: 100vh;">
  <div class="col-11 col-sm-9 col-md-7 col-xl-6 my-auto">
    <div class="card shadow pl-5 pr-5 pb-5 border_radius_10px">
      <div class="row justify-content-center">
        <img
        src="assets/images/Logo-ROYALFILMS.png"
        class="w-50 d-flex mx-auto "
        alt=""
        id="logo"
      />
                <div class="form-group">
          <p class="olvidar" style="margin-top: 1rem !important; margin-bottom: 2rem !important;"> <b class="text-center">Recuperar Contraseña.</b>Ingresa tu correo electrónico y te enviaremos un mensaje para ayudarte</p>			
          <input
            type="email"
            class="form-control border-0 shadow"
            id="password"
            [(ngModel)]="vector_datos.email"
          />
        </div>


        <div class="col-md-6 col-sm-12 mt-2">
          <button
            type="button"
            class="
              btn
              text-white
              border-0
              btn-lg btn-block
              shadow
              background_principal
            "

            (click)="function_reset_password()"
          >
            Restablecer
          </button>
        </div>
      </div>

      </div>
    </div>
  </div>
</div>
