<ngx-spinner [fullScreen] = "true" bdColor = "rgba(0,0,0,0.41)" size = "medium" color = "#fff" type = "ball-pulse" [fullScreen] = "false">
  <p style="color: white; font-size: 20px" > Iniciando sesión...</p>
</ngx-spinner>

<div
  class="
    container-fluid
    h-100
    background_position_center background_repeat_no_repeat background_size_cover
  "
  [ngStyle]="{ 'background-image': 'url(' + imagen_fondo_login + ')' }"
>
  <div class="row justify-content-center h-100">
    <div class="col-11 col-sm-9 col-md-7 col-xl-6 my-auto">
      <img
        src="assets/images/Logo Royal.png"
        class="w-50 d-flex mx-auto mb-5"
        alt=""
      />

      <div class="card shadow p-4 border_radius_10px">
        <div class="row justify-content-center">
          <div class="col-11">
            <div class="form-group">
              <label for="email">E-mail</label>
              <input
                type="email"
                class="form-control border-0 shadow"
                id="email"
                [(ngModel)]="vector_login.email"
                pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
              />
            </div>
          </div>

          <div class="col-11">
            <div class="form-group">
              <label for="password">password</label>
              <input
                type="password"
                class="form-control border-0 shadow"
                id="password"
                [(ngModel)]="vector_login.password"
              />
            </div>
          </div>

          <div class="col-12">
            <div style="margin-top: 3%;     text-align: center;">
              <a class="mb-2" routerLink="/recover-password/form" routerLinkActive="active">¿Olvidaste tu contraseña?</a> 
   
             </div>
          </div>

         

          <div class="col-7 mt-2">
            
   
            <button
              type="button"
              class="
                btn
                text-white
                border-0
                btn-lg btn-block
                shadow
                background_principal
              "
              (click)="
                function_user_login(vector_login.email, vector_login.password)
              "
              [disabled]="!vector_login.email || !vector_login.password"
            >
              Iniciar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
