import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserServicesService } from '../services/user-services.service';
import { FunctionsService } from './functions.service';

@Injectable({
  providedIn: 'root',
})
export class UserFunctionsService {
  constructor(
    private userServicesService: UserServicesService,
    private functionsService: FunctionsService,
    private spinner: NgxSpinnerService
  ) {}

  function_user_login = (body) => {
    this.spinner.show();
    return new Promise((resolve,reject) => {
      this.userServicesService.function_user_login(body)
      .subscribe(res => {
        res!=''?this.spinner.hide():this.spinner.show();

        res.data ?
        resolve(res.data):
        this.functionsService.function_modal_img(
          'Hola amigo',
          'Tuvimos un pequeño inconveniente identificando tu usuario, por favor revisar que la información este escrita correctamente.'
        );
      },err => {
        this.functionsService.function_modal_img(
          'Hola amigo',
          'Tuvimos un pequeño problema al consultar el servicio del login, no eres tú somos nosotros, porfavor intentar mas tarde'
        );
      })
    })
  }

  
  function_consultar_user = () => {
    return new Promise((resolve, reject) => {
      this.userServicesService
        .function_consultar_user()
        .subscribe(

          (res) => {
            res.status && resolve(res.data);
          },
          (err) => {
            this.functionsService.function_modal_img(
              'Hola amigo',
              'Tuvimos un pequeño problema al consultar de los productos, no eres tú somos nosotros, porfavor intentar mas tarde'
            );
            reject(err)
          }
        );
    });
  };

  function_filter = (body) => {
    return new Promise((resolve, reject) => {
      this.userServicesService
        .function_filter(body)
        .subscribe(
          (res) => {
            res.status && resolve(res.data);
          },
          (err) => {
            this.functionsService.function_modal_img(
              'Hola amigo',
              'Tuvimos un pequeño problema al consultar de los productos, no eres tú somos nosotros, porfavor intentar mas tarde'
            );
            reject(err)
          }
        );
    });
  };
  funcion_create_user = (body) => {
    return new Promise((resolve,reject) => {
      this.userServicesService.function_create_user(body)
      .subscribe(res => {
        console.log(res)
        res.data!=null?
        this.functionsService.function_modal_img(
          'Hola amigo',
          '¡Este correo electrónico ya se encuentra registrado!'
        ): resolve(res.data)

      },err => {
        this.functionsService.function_modal_img(
          'Hola amigo',
          'Tuvimos un pequeño problema al consultar el servicio del crear usuario, no eres tú somos nosotros, porfavor intentar mas tarde'
        );
      })
    })
  }

  function_edit = (body) => {
    return new Promise((resolve,reject) => {
      this.userServicesService.function_edit(body)
      .subscribe(res => {
        res.data!=null?
        this.functionsService.function_modal_img(
          'Hola amigo',
          '¡Este correo electrónico ya se encuentra registrado!'
        ): resolve(res.data)

      },err => {
        this.functionsService.function_modal_img(
          'Hola amigo',
          'Tuvimos un pequeño problema al consultar el servicio del crear usuario, no eres tú somos nosotros, porfavor intentar mas tarde'
        );
      })
    })
  }

  function_update_state = (body) => {
    return new Promise((resolve,reject) => {
      this.userServicesService.function_update_state(body)
      .subscribe(res => {
        res.data!=null?
        this.functionsService.function_modal_img(
          'Hola amigo',
          '¡El estado del usuario fue actualizado!'
        ): resolve(res.data)

      },err => {
        this.functionsService.function_modal_img(
          'Hola amigo',
          'Tuvimos un pequeño problema al consultar el servicio del crear usuario, no eres tú somos nosotros, porfavor intentar mas tarde'
        );
      })
    })
  }

  function_delete = (body) => {
    return new Promise((resolve,reject) => {
      this.userServicesService.function_delete(body)
      .subscribe(res => {
        res.data!=null?
        this.functionsService.function_modal_img(
          'Hola amigo',
          '¡El usuario fue eliminado!'
        ): resolve(res.data)

      },err => {
        this.functionsService.function_modal_img(
          'Hola amigo',
          'Tuvimos un pequeño problema al consultar el servicio del crear usuario, no eres tú somos nosotros, porfavor intentar mas tarde'
        );
      })
    })
  }

  // funcion_create_user = (data) => {
  //   return new Promise((resolve, reject) => {
  //     this.userServicesService
  //       .function_create_user(data)
  //       .subscribe(
  //         (res) => {
  //           if(res.status != false){
  //           }else{
  //             this.functionsService.function_modal_img(
  //               'Hola amigo',
  //               res.messenger
  //             );
  //             reject(false)
  //           }
  //         },
  //         (err) => {
  //           this.functionsService.function_modal_img(
  //             'Hola amigo',
  //             'Tuvimos un pequeño problema al activar los bonos, no eres tú somos nosotros, porfavor intentar mas tarde'
  //           );
  //           reject(err)
  //         }
  //       );
  //   });
  // }



  
}
