<ngx-spinner bdColor = "rgba(0, 0, 0, 0.0)" size = "medium" color = "#fff" type = "square-jelly-box" [fullScreen] = "false">
    <p style="color: black" > Loading... </p>
</ngx-spinner>

<div
  class="
    container-fluid
    h-100
    background_position_center background_repeat_no_repeat background_size_cover
  "
  [ngStyle]="{ 'background-image': 'url(' + imagen_fondo_login + ')' }"
>
  <div class="row justify-content-center" style="height: 100vh;">
    <div class="col-11 col-sm-9 col-md-7 col-xl-6 my-auto">
      <div class="card shadow pl-5 pr-5 pb-5 border_radius_10px">
        <div class="row justify-content-center">
          <img
          src="assets/images/Logo-ROYALFILMS.png"
          class="w-50 d-flex mx-auto "
          alt=""
          id="logo"
        />
<div class="col-12 justify-content-center mb-4"> <b>Restablecer Contraseña.</b> Por su seguridad, por favor realice el cambio de contraseña.
</div>
        


        <div class="col-6">
          <div class="form-group">
            <label for="password">Contraseña</label>
            <input
              type="password"
              class="form-control border-0 shadow"
              id="password"
              [(ngModel)]="vector_datos.password"
            />
          </div>
        </div>

          <div class="col-6">
            <div class="form-group">
              <label for="password">Confirmar contraseña</label>
              <input
                type="password"
                class="form-control border-0 shadow"
                id="password"
                [(ngModel)]="vector_datos.cpassword"
              />
            </div>
          </div>

          <div class="col-7 mt-2">
            <button
              type="button"
              class="
                btn
                text-white
                border-0
                btn-lg btn-block
                shadow
                background_principal
              "
              (click)="function_reset_password()"
            >
              Restablecer
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
