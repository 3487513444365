import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationFunctionsService } from '../services-functions/authentication-functions.service';
// import { RecoverPasswordFunctionsService } from '../services-functions/reset_password_functions.service';
import { FunctionsService } from '../services-functions/functions.service';
import { RecoverPasswordFunctionsService } from '../services-functions/recover_password_functions.service';

const redirectTo = JSON.parse(localStorage.getItem('recover-password'));
@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css'],
})
export class RecoverPasswordComponent implements OnInit {
  imagen_fondo_login: any =
  'assets/images/Fondo-inicio-Royal-films.jpg';

vector_datos = {
  user_hash: this.rutaActiva.snapshot.params.id, 
  password: '',
  cpassword: ''
}

constructor(
  private router: Router,
  private recoverPasswordService:RecoverPasswordFunctionsService,
  private functionsService: FunctionsService,
  private authenticationFunctionsService: AuthenticationFunctionsService,
  private rutaActiva: ActivatedRoute

) {}

ngOnInit(): void { 

}

public function_reset_password(){
  if(this.vector_datos.password==this.vector_datos.cpassword){
    this.recoverPasswordService.function_reset_password(this.vector_datos)
    .then(res => {
      this.functionsService.function_modal_img(
        'Hola amigo',
        '¡La contraseña fue restaurada exitosamente!'
      )
      .then(res => {
        this.router.navigate(['/login']);
      });
    })
  }else{
    this.functionsService.function_modal_img(
      'Hola amigo',
      '¡Por favor verifique la contraseña nueva, debe coincidir con la confirmación!'
    )
  }

  }
}
